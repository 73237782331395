jQuery(document).ready(function ($) {
    if ($('#scroll-to').length) {
        var distance = $('#scroll-to').offset().top;
        $(window).scroll(function () {
            if ($(this).scrollTop() >= distance - 72) {
                $('#scroll-to').removeClass("unsticky").addClass("sticky");
            } else {
                $('#scroll-to').removeClass("sticky").addClass("unsticky");
            }
        });
    }


    $('#sparrowModal').modal('show');

});
jQuery(document).ready(function ($) {
    $('.slick-row').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        dots: true,
        responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]
    });
    $(".hidden-check").click(function () {
        if ($(this).find('input').prop('checked')) {
            $(this).addClass("active");
        } else {
            $(this).removeClass("active");
        }
    });
});